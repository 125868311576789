var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isAllowedToViewDialog
        ? _c("AllowedToView", {
            attrs: { report: _vm.reportModel, editor: "veldwerk" },
            on: { handleAlert: _vm.allowedToView }
          })
        : _vm._e(),
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      _vm.isAllowedToView
        ? [
            _c(
              "v-container",
              {
                staticClass:
                  "reportContainer container--wide reportContainer--veldwerk",
                attrs: { "grid-list-lg": "" }
              },
              [
                !_vm.isLoading
                  ? _c(
                      "v-layout",
                      { attrs: { row: "", wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "", lg5: "" } }, [
                          _c(
                            "div",
                            { staticClass: "scrollColumn" },
                            [
                              _c(
                                "div",
                                {
                                  class: {
                                    "reject-border":
                                      _vm.reportModel.reject_reasons.length
                                  }
                                },
                                [
                                  _vm.reportModel
                                    ? _c(
                                        "div",
                                        { staticClass: "elementPanel profile" },
                                        [
                                          _vm.isLoading
                                            ? _c("LoaderCard", {
                                                attrs: {
                                                  flat: "",
                                                  type: "spinner--center",
                                                  minHeight: "300px"
                                                }
                                              })
                                            : _vm._e(),
                                          !_vm.isLoading
                                            ? [
                                                _c(
                                                  "v-layout",
                                                  {
                                                    staticClass:
                                                      "profileContainer",
                                                    attrs: { row: "", wrap: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      [
                                                        [
                                                          _c(
                                                            "h1",
                                                            {
                                                              staticClass:
                                                                "profile__userName"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .reportModel
                                                                    .case_number
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "profile__userRoles"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .reportModel
                                                                      .type
                                                                      ? _vm
                                                                          .reportModel
                                                                          .type
                                                                          .name
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Naam aanvrager"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .applicant
                                                                ? _vm
                                                                    .reportModel
                                                                    .applicant
                                                                    .name
                                                                : ""
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [_vm._v("Adres")]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _vm.reportModel.address
                                                          ? _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href: _vm.reportModel.googleMapUrl(),
                                                                  target:
                                                                    "_blank"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .reportModel
                                                                        .address
                                                                        .street
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .reportModel
                                                                        .address
                                                                        .number
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .reportModel
                                                                        .address
                                                                        .number_add
                                                                    ) +
                                                                    ", " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .reportModel
                                                                        .address
                                                                        .postcode
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .reportModel
                                                                        .address
                                                                        .city
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [_vm._v("Bureau")]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .organization
                                                                ? _vm
                                                                    .reportModel
                                                                    .organization
                                                                    .name
                                                                : ""
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Rapport type"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .type
                                                                ? _vm
                                                                    .reportModel
                                                                    .type.name
                                                                : ""
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Opnamedatum"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "dateFormatTime"
                                                              )(
                                                                _vm.reportModel
                                                                  .planned_at
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "profile.reportTag"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      _vm._l(
                                                        _vm.reportModel.tags,
                                                        function(tag, index) {
                                                          return _c(
                                                            "p",
                                                            { key: index },
                                                            [
                                                              _vm.reportModel
                                                                .tags.length > 1
                                                                ? _c("span", [
                                                                    tag[0]
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  tag.name
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "," +
                                                                                _vm._s(
                                                                                  tag.name
                                                                                )
                                                                            )
                                                                          ]
                                                                        )
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          tag.name
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [_vm._v("Naam TC")]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _vm.reportModel
                                                          .organization_tc
                                                          ? _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .reportModel
                                                                    .organization_tc
                                                                    .name
                                                                )
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _vm.reportModel
                                                      .isAannemersReport
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn",
                                                            attrs: { sm4: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "infoPanelColumn__fadedText"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Naam Aannemer"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.reportModel
                                                      .isAannemersReport
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn",
                                                            attrs: { sm4: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "infoPanelColumn__fadedText"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Naam Expert"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _vm.reportModel
                                                          .organization_expert
                                                          ? _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .reportModel
                                                                    .organization_expert
                                                                    .name
                                                                )
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm.reportModel
                                                  .isAannemersReport
                                                  ? _c(
                                                      "v-layout",
                                                      { attrs: { row: "" } },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn",
                                                            attrs: { sm4: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "infoPanelColumn__fadedText"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Naam Expert"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _vm.reportModel
                                                          .second_expert
                                                          ? _c(
                                                              "v-flex",
                                                              {
                                                                staticClass:
                                                                  "infoPanelColumn",
                                                                attrs: {
                                                                  sm8: ""
                                                                }
                                                              },
                                                              [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .reportModel
                                                                        .second_expert
                                                                        .name
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Indieningsdatum"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "dateFormat"
                                                              )(
                                                                _vm.reportModel
                                                                  .applicant_submitted_at
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Type gebouw"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _vm.generalKeys
                                                          .SoortGebouw
                                                          ? _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .generalKeys
                                                                    .SoortGebouw
                                                                    .value
                                                                )
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [_vm._v("Bouwjaar")]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _vm.generalKeys.BouwJaar
                                                          ? _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .generalKeys
                                                                    .BouwJaar
                                                                    .value
                                                                )
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Eigendom sinds"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _vm.generalKeys
                                                          .EigendomSinds
                                                          ? _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .generalKeys
                                                                    .EigendomSinds
                                                                    .value
                                                                )
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Zaakbegeleider"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _vm.generalKeys
                                                          .presetAtTaxations_Name
                                                          ? _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .generalKeys
                                                                    .presetAtTaxations_Name
                                                                    .value
                                                                )
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Verhaal aanvrager"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _vm.generalKeys
                                                          .storyOfApplicant
                                                          ? _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .generalKeys
                                                                    .storyOfApplicant
                                                                    .value
                                                                )
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Opmerking deskundige"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _c("p", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.sanitizeString(
                                                                _vm.generalKeys
                                                                  .ExpertComment
                                                                  ? _vm
                                                                      .generalKeys
                                                                      .ExpertComment
                                                                      .value
                                                                  : "-"
                                                              )
                                                            )
                                                          }
                                                        }),
                                                        _c("p", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.sanitizeString(
                                                                _vm.generalKeys
                                                                  .OpmerkingDeskundige
                                                                  ? _vm
                                                                      .generalKeys
                                                                      .OpmerkingDeskundige
                                                                      .value
                                                                  : "-"
                                                              )
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Opmerking aanvrager"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _c("p", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.sanitizeString(
                                                                _vm.generalKeys
                                                                  .relevantRemarksApplicant
                                                                  ? _vm
                                                                      .generalKeys
                                                                      .relevantRemarksApplicant
                                                                      .value
                                                                  : "-"
                                                              )
                                                            )
                                                          }
                                                        }),
                                                        _c("p", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.sanitizeString(
                                                                _vm.generalKeys
                                                                  .OpmerkingAanvrager
                                                                  ? _vm
                                                                      .generalKeys
                                                                      .OpmerkingAanvrager
                                                                      .value
                                                                  : "-"
                                                              )
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Datum ondertekening TC"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.reportModel
                                                                  .approved_at ===
                                                                  "0000-00-00 00:00:00"
                                                                  ? "-"
                                                                  : _vm.formatDateSeconds(
                                                                      _vm
                                                                        .reportModel
                                                                        .approved_at
                                                                    )
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Totaal schadebedrag incl. BTW"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm.reportModel
                                                      .total_repairs
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn",
                                                            attrs: { sm8: "" }
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "currency"
                                                                  )(
                                                                    _vm
                                                                      .reportModel
                                                                      .total_repairs
                                                                      .total_in
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Calculatiemodel"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm.reportModel
                                                      .calculation_model
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn",
                                                            attrs: { sm8: "" }
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .reportModel
                                                                      .calculation_model
                                                                      .name
                                                                  ) +
                                                                  " (" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .reportModel
                                                                      .calculation_model
                                                                      .from
                                                                  ) +
                                                                  ") " +
                                                                  _vm._s(
                                                                    _vm.getVatText()
                                                                  ) +
                                                                  " "
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm.reportModel
                                    ? _c(
                                        "div",
                                        { staticClass: "elementPanel profile" },
                                        [
                                          !_vm.steekproef
                                            ? [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Afwijsreden"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm.rejectReportReasons,
                                                            "item-value": "id",
                                                            "item-text": "name",
                                                            multiple: "",
                                                            "return-object": "",
                                                            disabled:
                                                              _vm.isLockedByUser
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedReportRejectReasons,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.selectedReportRejectReasons = $$v
                                                            },
                                                            expression:
                                                              "selectedReportRejectReasons"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Afwijsuitleg"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _c("v-textarea", {
                                                          attrs: {
                                                            placeholder:
                                                              "Uitleg voor het afkeuren",
                                                            name: "explanation",
                                                            disabled:
                                                              _vm.isLockedByUser
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.debounceExplanation
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.reportModel
                                                                .reject_comment,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.reportModel,
                                                                "reject_comment",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "reportModel.reject_comment"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            : _vm._e(),
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass:
                                                    "infoPanelColumn",
                                                  attrs: { xs12: "" }
                                                },
                                                [
                                                  _c("RejectReason", {
                                                    attrs: {
                                                      model: _vm.reportModel
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.reportModel.validations
                                            ? _c(
                                                "v-layout",
                                                {
                                                  staticClass:
                                                    "pl-0 item__list item__list--reports",
                                                  attrs: { wrap: "" }
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.reportModel.validations,
                                                    function(validation, key) {
                                                      return [
                                                        validation !== "ok"
                                                          ? _c(
                                                              "v-flex",
                                                              {
                                                                key: key,
                                                                staticClass:
                                                                  "list__item",
                                                                attrs: {
                                                                  xs12: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "ValidationWarning",
                                                                  {
                                                                    attrs: {
                                                                      validation: validation,
                                                                      validationKey: key
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm.damages
                                ? [
                                    !_vm.steekproef
                                      ? _c("DamagePanel", {
                                          ref: "damagePanel",
                                          attrs: {
                                            damages: _vm.damages,
                                            includes: [
                                              "validations",
                                              "reject_reasons",
                                              "media",
                                              "repairs"
                                            ],
                                            visibility: _vm.damageVisibility,
                                            disabled: _vm.isLockedByUser
                                          },
                                          on: { reloadDamages: _vm.initialize }
                                        })
                                      : _c("DamagePanel", {
                                          ref: "damagePanel",
                                          attrs: {
                                            damages: _vm.damages,
                                            includes: [
                                              "validations",
                                              "reject_reasons",
                                              "media",
                                              "repairs"
                                            ],
                                            visibility:
                                              _vm.damageVisibilitySteekProef,
                                            disabled: _vm.isLockedByUser
                                          },
                                          on: { reloadDamages: _vm.initialize }
                                        })
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]),
                        _c("v-flex", { attrs: { xs12: "", lg7: "" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "elementPanel elementPanel--iframe profile"
                            },
                            [
                              !_vm.hasPdf && _vm.reportModel.is_final
                                ? _c("p", [
                                    _vm._v(
                                      " Er is een probleem opgetreden met de PDF van dit rapport. Graag contact opnemen met de helpdesk. "
                                    )
                                  ])
                                : _vm._e(),
                              !_vm.reportModel.is_final
                                ? _c("p", [
                                    _vm._v("Dit rapport is nog niet afgerond.")
                                  ])
                                : _vm._e(),
                              _vm.hasPdf && _vm.reportModel.is_final
                                ? _c("iframe", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "96%",
                                      "min-height": "600px"
                                    },
                                    attrs: {
                                      src: _vm.fileUrl ? _vm.fileUrl : null
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("ApprovalBar", {
              attrs: { backUrl: "/reports/" + _vm.reportModel.uuid },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function() {
                      return [
                        [
                          _c(
                            "div",
                            { staticClass: "actionContainer" },
                            [
                              _c(
                                "MiButton",
                                {
                                  attrs: {
                                    color: "secondary",
                                    icon: "remove_red_eye",
                                    small: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openRejectReasonDialog()
                                    }
                                  }
                                },
                                [_vm._v(" Bekijk afkeurredenen ")]
                              )
                            ],
                            1
                          )
                        ],
                        [
                          _c(
                            "div",
                            { staticClass: "actionContainer" },
                            [
                              _vm.reportModel.hasStatus([
                                "veldwerk_corrected",
                                "approved",
                                "veldwerk_onhold"
                              ])
                                ? _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: _vm.isLockedByUser,
                                        color: "primary",
                                        icon: "send",
                                        small: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.sendToStatus("toJz")
                                        }
                                      }
                                    },
                                    [_vm._v(" Controle JZ ")]
                                  )
                                : _vm._e(),
                              _vm.reportModel.hasStatus(["specials_in_review"])
                                ? _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: _vm.isLockedByUser,
                                        color: "primary",
                                        icon: "send",
                                        small: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.sendToStatus("backtoJz")
                                        }
                                      }
                                    },
                                    [_vm._v(" Controle Schade Afhandeling ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        !_vm.isObjection && !_vm.isStuwmeer && !_vm.isZienswijze
                          ? [
                              !_vm.steekproef
                                ? _c(
                                    "div",
                                    { staticClass: "actionContainer" },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            disabled: _vm.isLockedByUser,
                                            color: "warning",
                                            small: "true",
                                            icon: "cancel"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.toggleApproveDialog(
                                                "veldwerk_rejected",
                                                "Versturen naar:",
                                                "rejected"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Afkeuren ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: _vm.isLockedByUser,
                                        color: "success",
                                        small: "true",
                                        icon: "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleApproveDialog(
                                            "downloaded",
                                            "Versturen naar:",
                                            "approved"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Goedkeuren ")]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isZienswijze
                          ? [
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: _vm.isLockedByUser,
                                        color: "warning",
                                        small: "true",
                                        icon: "cancel"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleApproveDialog(
                                            "zienswijze_veldwerk_rejected",
                                            "Versturen naar:",
                                            "rejected"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Afkeuren ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: _vm.isLockedByUser,
                                        color: "success",
                                        small: "true",
                                        icon: "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleApproveDialog(
                                            "zienswijze_closed",
                                            "Versturen naar:",
                                            "approved"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Goedkeuren ")]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isStuwmeer
                          ? [
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: _vm.isLockedByUser,
                                        color: "warning",
                                        small: "true",
                                        icon: "cancel"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleApproveDialog(
                                            "smr_veldwerk_rejected",
                                            "Versturen naar:",
                                            "rejected"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Afkeuren ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: _vm.isLockedByUser,
                                        color: "success",
                                        small: "true",
                                        icon: "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleApproveDialog(
                                            "smr_closed",
                                            "Versturen naar:",
                                            "approved"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Goedkeuren ")]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isObjection
                          ? [
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: _vm.isLockedByUser,
                                        color: "warning",
                                        small: "true",
                                        icon: "cancel"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleApproveDialog(
                                            "objection_veldwerk_rejected",
                                            "Versturen naar:",
                                            "rejected"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Afkeuren ")]
                                  )
                                ],
                                1
                              ),
                              !_vm.reportModel.hasStatus(
                                "objection_pending_close"
                              )
                                ? _c(
                                    "div",
                                    { staticClass: "actionContainer" },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            disabled: _vm.isLockedByUser,
                                            color: "success",
                                            small: "true",
                                            icon: "check_circle"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.toggleApproveDialog(
                                                "objection_pending_close",
                                                "Versturen naar:",
                                                "approved"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Goedkeuren ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.reportModel.hasStatus(
                                "objection_pending_close"
                              )
                                ? _c(
                                    "div",
                                    { staticClass: "actionContainer" },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            disabled: _vm.isLockedByUser,
                                            color: "success",
                                            small: "true",
                                            icon: "check_circle"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.toggleApproveDialog(
                                                "objection_closed",
                                                "Versturen naar:",
                                                "approved"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Goedkeuren ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1632115007
              )
            })
          ]
        : _vm._e(),
      _vm.approvalDialogOpen
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.approvalDialogOpen,
                callback: function($$v) {
                  _vm.approvalDialogOpen = $$v
                },
                expression: "approvalDialogOpen"
              }
            },
            [
              _c("ApprovalDialog", {
                attrs: {
                  report: _vm.reportModel,
                  title: _vm.title,
                  status: _vm.status,
                  role: "veldwerk",
                  approval: _vm.approval,
                  planning: _vm.showPlanning,
                  firstTimeRight: _vm.approval ? true : false
                },
                model: {
                  value: _vm.approvalDialogOpen,
                  callback: function($$v) {
                    _vm.approvalDialogOpen = $$v
                  },
                  expression: "approvalDialogOpen"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isSendingToStatus
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isSendingToStatus,
                callback: function($$v) {
                  _vm.isSendingToStatus = $$v
                },
                expression: "isSendingToStatus"
              }
            },
            [
              _c(
                "DefaultDialog",
                {
                  on: {
                    close: function($event) {
                      _vm.isSendingToStatus = false
                    }
                  }
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v(" " + _vm._s(_vm.sendStatusDialog.title) + " ")
                  ]),
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _vm.isLoading
                        ? _c("LoaderCard", {
                            attrs: { flat: "", type: "spinner--center" }
                          })
                        : _vm._e(),
                      !_vm.isLoading
                        ? [
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              _c("span", { staticClass: "fadedText" }, [
                                _vm._v(_vm._s(_vm.sendStatusDialog.description))
                              ])
                            ]),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", "mt-3": "" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.users,
                                    "item-value": "id",
                                    "item-text": "name",
                                    "hide-details": "",
                                    label: "Medewerker",
                                    placeholder: "Zoek een Medewerker",
                                    "search-input": _vm.userSearch
                                  },
                                  on: {
                                    "update:searchInput": function($event) {
                                      _vm.userSearch = $event
                                    },
                                    "update:search-input": function($event) {
                                      _vm.userSearch = $event
                                    },
                                    keyup: _vm.debouncedSearchUsers
                                  },
                                  model: {
                                    value: _vm.selectedUser,
                                    callback: function($$v) {
                                      _vm.selectedUser = $$v
                                    },
                                    expression: "selectedUser"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      !_vm.isLoading
                        ? _c(
                            "v-layout",
                            {
                              attrs: { "justify-end": "", "align-center": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-3 fadedText link",
                                      on: {
                                        click: function($event) {
                                          _vm.isSendingToStatus = false
                                        }
                                      }
                                    },
                                    [_vm._v("Annuleren")]
                                  ),
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "primary",
                                        small: "true",
                                        disabled: !_vm.selectedUser
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.sendStatusDialog.confirmAction()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.sendStatusDialog.confirm) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.viewRejectReasonDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "800" },
              model: {
                value: _vm.viewRejectReasonDialog,
                callback: function($$v) {
                  _vm.viewRejectReasonDialog = $$v
                },
                expression: "viewRejectReasonDialog"
              }
            },
            [
              _c(
                "DefaultDialog",
                {
                  on: {
                    close: function($event) {
                      _vm.viewRejectReasonDialog = false
                    }
                  }
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v("Actuele afkeurredenen")
                  ]),
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _vm.isLoading
                        ? _c("LoaderCard", {
                            attrs: { flat: "", type: "spinner--center" }
                          })
                        : _vm._e(),
                      !_vm.isLoading
                        ? [
                            _c(
                              "v-layout",
                              {
                                staticClass: "v-card__text scrollContainer",
                                attrs: { wrap: "" }
                              },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "fadedText font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.reportModel.case_number)
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("RejectReason", {
                                      attrs: {
                                        model: _vm.reportModel,
                                        showCategories: true
                                      }
                                    }),
                                    !_vm.reportModel.reject_reasons &&
                                    !_vm.reportModel.reject_comment
                                      ? _c(
                                          "span",
                                          { staticClass: "fadedText" },
                                          [_vm._v(" Geen afkeurredenen ")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._l(_vm.rejectReasonDamages, function(
                                  rejectReasonDamage,
                                  DamageIndex
                                ) {
                                  return [
                                    rejectReasonDamage.reject_reasons &&
                                    rejectReasonDamage.reject_reasons.length
                                      ? _c(
                                          "v-flex",
                                          {
                                            key: rejectReasonDamage.id,
                                            attrs: { xs12: "" }
                                          },
                                          [
                                            _c(
                                              "v-layout",
                                              { attrs: { wrap: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "fadedText font-weight-bold"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Schade " +
                                                            _vm._s(
                                                              DamageIndex + 1
                                                            ) +
                                                            " - " +
                                                            _vm._s(
                                                              rejectReasonDamage.name
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c("RejectReason", {
                                                      attrs: {
                                                        rejectReasons:
                                                          _vm.rejectDamageReasons,
                                                        model: rejectReasonDamage,
                                                        showCategories: true
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                }),
                                _vm.reportLogs
                                  ? _c(
                                      "div",
                                      { staticClass: "rejectMessages" },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "", "pt-4": "" } },
                                          [
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "rejectMessages__title"
                                              },
                                              [_vm._v("Vorige afkeurredenen")]
                                            )
                                          ]
                                        ),
                                        _vm._l(_vm.reportLogs, function(
                                          reportLog,
                                          index
                                        ) {
                                          return [
                                            reportLog
                                              ? _c(
                                                  "v-flex",
                                                  {
                                                    key:
                                                      "default-" +
                                                      reportLog.id +
                                                      index,
                                                    staticClass:
                                                      "rejectMessages__content",
                                                    attrs: { xs12: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "pa-2" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "message__created"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateFormatTime"
                                                                  )(
                                                                    reportLog.created_at
                                                                  )
                                                                ) +
                                                                " door "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-weight":
                                                                    "bold"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    reportLog.user
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            reportLog.is_internal
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "intern"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "intern"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "pa-2" },
                                                      [
                                                        _c("pre", {
                                                          staticClass:
                                                            "message__text",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.getStyledStatusLog(
                                                                reportLog
                                                              )
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }