/* eslint-disable guard-for-in */
import { Component, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { formatDate } from '@/support/String';
import ErrorHandler from '@/support/ErrorHandler';
import { parseWarning } from '@/support/Warning';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';
import { DamageVisibility, Panel } from '@/components/damage-panel/DamagePanel';
import { Report as ReportModel, Answer } from '@/models/Report';
import { RejectReason as RejectReasonModel } from '@/models/RejectReason';
import { Damage } from '@/models/DamageNew';
import { AlertBar } from '@/support/AlertBar';
import { SendStatusDialog } from '@/components/report-editors/ReportEditors';
import { User } from '@/models/User';
import { ReportLog } from '@/models/ReportLog';
import { getStyledStatusLog } from '@/support/ReportStatus';
import { ReportLogFilter } from '@/views/Reports/Communicatie/Communicatie';
import { Lock } from '@/support/Lock';
import IdleWarningDialog from '@/components/IdleWarningDialog';
import { sanitizeString } from '@/support/String';

@Component<Schadebepaling>({
  filters: {
    dateYear: (date: string) => {
      if (! date) { return ''; }
      return formatDate(date, 'yyyy');
    },
  },
})
export default class Schadebepaling extends IdleWarningDialog {
  public $pageTitle = 'Schadebepaling';

  // LOCK
  protected lock: Lock = new Lock();

  protected lockKey = '';

  protected reportModel: ReportModel = new ReportModel();

  protected damageModel: Damage = new Damage();

  protected damages: Damage[] = [];

  protected currentDamage: Damage | null = null;

  protected initialLoad = true;

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  protected sendStatusDialog: SendStatusDialog = {};

  protected isSendingToStatus = false;

  protected viewRejectReasonDialog = false;

  // approval bar
  protected status = '';

  protected title = '';

  protected fileUrl = '';

  protected isZienswijze = false;

  protected isStuwmeer = false;

  protected isObjection = false;

  protected showPlanning = false;

  protected approval = '';

  protected approvalDialogOpen = false;

  protected isLoading = false;

  protected panels: Panel[] | null = [];

  protected isOpenPanels = false;

  protected hasPdf = true;

  protected selectedReportRejectReasons: any[] = [];

  protected rejectReportReasons = [];

  protected rejectDamageReasons = [];

  protected planned_at_date = '';

  protected approved_at_date = '';

  protected submitted_at_date = '';

  protected planned_at = '';

  protected approved_at = '';

  protected submitted_at = '';

  protected debounceExplanation:Function = debounce(this.updateExplanation, 1500);

  // JZ users
  protected debouncedSearchUsers: Function = debounce(this.handleSearchUser, 300);

  protected userSearch = '';

  protected selectedUser = '';

  protected users: User[] = [];

  // List ReportLogs
  protected reportLogs: ReportLog[] = [];

  protected mask: Mask = {
    time: '##:##',
  }

  protected generalKeys: GeneralKey = GeneralKeys;

  protected isUploadingFile = false;

  protected activeTab = 'tab-schades';

  protected mounting = true;

  protected vatTypes: NumberArray[] = [
    {
      name: 6,
      value: 6,
    },
    {
      name: 9,
      value: 9,
    },
  ];

  protected damageVisibility: DamageVisibility = {
    status: true,
    rejectReasons: true,
    reject: true,
    approve: true,
    validations: true,
    repairs: true,
    veldwerk: true,
    ambtshalve: true,
  };

  protected damageVisibilitySteekProef: DamageVisibility = {
    status: true,
    rejectReasons: true,
    validations: true,
    repairs: true,
    veldwerk: true,
  };

  protected created() {
    window.onbeforeunload = () => {
      this.lock.unlock(this.lockKey);
    };
  }

  public mounted() {
    // LOCK
    this.createLock();

    this.initialize();
  }

  public destroyed() {
    this.updateExplanation();
    this.lock.unlock(this.lockKey);
  }

  protected async initialize() {
    this.isLoading = true;
    this.mounting = true;
    await this.getReport();
    await this.getDamages();
    this.fetchReportLogs();
    this.isLoading = false;
  }

  protected async createLock() {
    this.lockKey = `report:${this.$route.params.id}:editor:schadebepaling`;
    await this.lock.checkLock(this.lockKey);
    await this.lock.initLock(this.lockKey);
    this.setAlert();
  }

  protected get isLockedByUser(): boolean {
    return this.lock.isLockedByUser;
  }

  protected setAlert() {
    if (this.lock.user) {
      this.$store.state.alert.message = AlertBar.setIsLockedMessage(this.lock.user);
      this.$store.state.alert.show = true;
    } else {
      this.$store.state.alert.show = false;
    }
  }

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }

  protected fetchReportLogs() {
    const filter: {[key: string]: string | string[]} = {
      report: this.$route.params.id,
      types: ['reject_reason'],
    };

    new ReportLog()
      .filter(filter)
      .limit(300)
      .sort('created_at', 'DESC')
      .all()
      .then((reportLogs: ReportLog[]) => {
        this.reportLogs = reportLogs;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getStyledStatusLog(reportLog: ReportLogFilter) {
    if (reportLog && reportLog.text) {
      return getStyledStatusLog(reportLog.text, reportLog.type);
    }

    return '';
  }

  protected async getReport() {
    await new ReportModel()
      .include(['applicant', 'answers', 'validations', 'total_repairs', 'reject_reasons', 'organization', 'overrule_reasons'])
      .find(this.$route.params.id)
      .then((reportModel: ReportModel) => {
        this.reportModel = reportModel;

        if (reportModel.isZienswijzeReport) {
          this.damageVisibility.editAmbtshalve = true;
        }

        if (this.reportModel.reject_reasons) {
          this.selectedReportRejectReasons = this.reportModel.reject_reasons;
        }

        if (reportModel.links.quick === null) {
          this.hasPdf = false;
        } else {
          this.hasPdf = true;
        }

        if (! this.fileUrl) {
          this.fileUrl = reportModel.links.quick;
        }

        this.initBreadcrumb();
        this.setGeneralInfo();

        this.getRejectReasons();
        this.pingReport();
        this.showApprovalButtons();

        this.isAllowedToViewDialog = true;

        this.$nextTick(() => {
          this.mounting = false;
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getDamages() {
    this.damages = await new Damage()
      .refactor()
      .include('validations')
      .filter({
        report: this.$route.params.id,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected showApprovalButtons() {
    this.isZienswijze = this.reportModel.hasStatus(['zienswijze_approved', 'zienswijze_corrected']);

    if (this.reportModel.type) {
      this.isStuwmeer = this.reportModel.isStuwmeerRegelingReport;
      this.isObjection = this.reportModel.isBezwaarReport;
    }
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  protected pingReport() {
    if (! this.reportModel || ! this.reportModel.uuid) {
      return;
    }

    this.reportModel
      .pingReport(this.reportModel.uuid)
      .then((report: ReportModel) => {
      //
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getRejectReasons() {
    new RejectReasonModel()
    // .filter({type: ['damage', 'report']})
      .limit(100)
      .all()
      .then((response: any) => {
        if (! response) {
          return;
        }

        let previousGroup = '';
        response.forEach((reason: any) => {
          if (reason.type === 'report') {
            if (previousGroup !== reason.group) {
              (this.rejectReportReasons as any).push({
                header: reason.group,
              });
              (this.rejectReportReasons as any).push(reason);
              previousGroup = reason.group;
              return;
            }
            (this.rejectReportReasons as any).push(reason);
            previousGroup = reason.group;
          }

          if (reason.type === 'damage') {
            if (previousGroup !== reason.group) {
              (this.rejectDamageReasons as any).push({
                header: reason.group,
              });
              (this.rejectDamageReasons as any).push(reason);
              previousGroup = reason.group ? reason.group : '';
              return;
            }
            (this.rejectDamageReasons as any).push(reason);
            previousGroup = reason.group;
          }
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle rapporten', path: '/reports' },
          { name: this.reportModel.case_number, path: `/reports/${this.reportModel.uuid}` },
          { name: 'Schadebepaling' },
        ],
      });
  }

  protected parseWarning(alert: string) {
    return parseWarning(alert);
  }

  protected formatDateSeconds(date: string) {
    return formatDate(date, 'dd-LL-yyyy HH:mm');
  }

  protected getVatText() {
    if (this.reportModel && this.reportModel.calculation_model_vat_low) {
      return `met ${this.reportModel.calculation_model_vat_low}% BTW`;
    }
    return '';
  }

  protected parseIsLate() {
    if (this.reportModel.is_late) {
      return 'Ja';
    }
    return 'Nee';
  }

  protected setGeneralInfo() {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.generalKeys) {
      this.generalKeys[key].value = '';
    }

    const keys = Object.keys(this.generalKeys);
    if (! this.reportModel.answers) {
      return;
    }

    this.reportModel.answers.forEach((anwser: Answer) => {
      if (keys.includes(anwser.key ? anwser.key : '')) {
        (this.generalKeys as any)[anwser.key ? anwser.key : ''].value = anwser.value;
      }
    });
  }

  protected updateExplanation() {
    const payload = {
      reject_comment: this.reportModel.reject_comment,
    };

    this.reportModel
      .update(payload)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected toggleApproveDialog(status: string, title: string, approval: string) {
    status === 'in_progress' ? this.showPlanning = true : this.showPlanning = false;
    this.status = status;
    this.title = title;
    this.approval = approval;
    this.approvalDialogOpen = true;
  }

  protected get steekproef(): boolean {
    return this.reportModel.hasStatus('submit_no_test');
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.initialize();
  }

  @Watch('selectedReportRejectReasons')
  public reasonChanged() {
    if (this.mounting) return;

    const rejectIds = this.selectedReportRejectReasons.map((reason: any) => reason.id);

    const payload = {
      reject_reasons: rejectIds,
    };

    this.reportModel
      .include('reject_reasons')
      .update(payload)
      .then((reportModel: ReportModel) => {
        this.reportModel.reject_reasons = reportModel.reject_reasons;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected sendToStatus(type: string) {
    if (type === 'toJz') {
      this.sendStatusDialog = {
        title: 'Versturen naar JZ',
        description: 'Wil je dit rapport ter controle versturen aan JZ?',
        confirmAction: this.updateReportToStatus,
        status: 'specials_in_review',
        confirm: 'Ja, versturen',
      };
    } else {
      this.sendStatusDialog = {
        title: 'Versturen naar Schade Afhandeling',
        description: 'Heb je dit rapport beoordeeld en wil je dit terugsturen naar Schade Afhandeling?',
        confirmAction: this.updateReportToStatus,
        status: 'specials_reviewed',
        confirm: 'Ja, versturen',
      };
    }

    this.listUsers();
    this.isSendingToStatus = true;
  }

  protected updateReportToStatus() {
    this.isLoading = true;
    new ReportModel(this.reportModel)
      .update({
        status: this.sendStatusDialog.status,
        jz_user: this.selectedUser,
      })
      .then(() => {
        this.$router.push(`/reports/${this.reportModel ? this.reportModel.uuid : ''}`);
      });
  }

  protected handleSearchUser(query: any) {
    if (! query) {
      return;
    }

    this.listUsers();
  }

  protected async listUsers(): Promise<void> {
    this.users = await new User()
      .filter({
        search: this.userSearch,
        types: ['damage_handler', 'damage_controller'],
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected rejectReasonDamages: Damage[] | null = null;

  protected openRejectReasonDialog() {
    this.getDamageRejectReason();
    this.viewRejectReasonDialog = true;
  }

  protected async getDamageRejectReason() {
    this.rejectReasonDamages = await new Damage()
      .refactor()
      .include('reject_reasons')
      .filter({
        report: this.$route.params.id,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    // this.rejectReasonDamages = damages.map((damage: Damage) => {
    //   return damage;
    // });
  }
}

export interface StringArray {
  [key: string]: string;
}

export interface NumberArray {
  [key: string]: number;
}

interface UserFilter {
  search?: string;
}

interface Mask {
  [key: string]: string;
}
